import bets_img from '../images/best_bets_adj.png';
import exat_img from '../images/exat_code_snip.png';
import cs589_img from '../images/cs589_final.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub } from '@fortawesome/free-brands-svg-icons'
import {motion as m} from 'framer-motion';
import { useState } from 'react';

const Modal = ({ isOpen, onClose, imageSrc, caption }) => {
    if (!isOpen) return null; // Don't render the modal if not open

    return (
        <div className="modal">
            <div className="modal-content">
                <span className="close" onClick={onClose}>&times;</span>
                <img src={imageSrc} alt={caption} />
                <div id="caption">{caption}</div>
            </div>
        </div>
    );
};

export default function Projects() {
    const [modalOpen, setModalOpen] = useState(false);
    const [currentImage, setCurrentImage] = useState(null);
    const [caption, setCaption] = useState('');

    const openModal = (src, caption) => {
        setCurrentImage(src);
        setCaption(caption);
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
        setCurrentImage(null);
        setCaption('');
    };
    return (
        <>
            <m.div className="project-section-content-box" initial={{opacity: 0.1}}
                animate={{opacity: 1}}
                transition={{duration: 0.5, ease: "easeIn"}}>
                <h1>Projects</h1>
                <div className="project-section-content">        
                        <ul className="project-list">
                                <li>
                                    <a href="https://github.com/Andrei-Treil/betting_bot/tree/andrei-dev" target="_blank" rel="noreffer noreferrer" className="projLink">
                                    <h2>NBA Betting Project <FontAwesomeIcon icon={faGithub}/></h2></a>
                                    <div className="project-div">
                                        <div className="project-text">
                                        <p>
                                            <h3>Background</h3>
                                            As an avid fan of the NBA, I am extremely interested in the intersection between data science and basketball.
                                            Due to the stochastic nature of sports games, developing a highly accurate model to predict the outcomes
                                            of NBA games is unrealistic. Given the recent explosion in popularity of the sports betting industry,
                                            comparing my predictions against betting odds provided by Vegas serves as a great metric for determining
                                            the quality of my models.
                                            <br /><br />
                                            Previous experience developing an MLB betting model for a friend (who now works as a sports betting trader at Draft Kings)
                                            provided me insight on how to develop an effective betting strategy. Specifically, I decided to implement a variation of 
                                            the <a href="https://en.wikipedia.org/wiki/Kelly_criterion" target="_blank" rel="noreffer noreferrer">Kelly Criterion</a> betting strategy.
                                        </p>
                                        <p>
                                            <h3>Collecting Data</h3>
                                            The first challenge associated with this project was identifying and preparing the necessary data for training and assessment. Initially,
                                            training features solely consisted of the difference between the home and away teams' end of season averages. Models trained on this data
                                            did not perform well, necessitating better data. The first improvement made which saw meaningful impact on performance, was the introduction
                                            of injury impact on individual games. By collecting individual player's on/off statistics, feature vectors could include the impact of injuries 
                                            on the outcome of games.
                                            <br /><br />
                                            The final major improvement to the data was the generation of day-to-day game statistics. Instead of using end of season totals, features were
                                            now being generated using each team's averages up to the date of a given game. This change presented a significant challenge as instead of scraping
                                            one page per team for a single season, I had to scrape at least 82 pages for each team in a given season (an increase from 30 pages scraped to over 2460). 
                                            To collect this data in my lifetime, I had to leverage concurrency methods which inspired my reading of Operating Systems: Three Easy Pieces (OSTEP).
                                            <br /><br />
                                            In order to compare my models against Vegas odds, I collected historic betting data from <a href="https://www.vegasinsider.com/nba/odds/las-vegas/" 
                                            target="_blank" rel="noreffer noreferrer">Vegas Insider's NBA odds</a>. These odds only date back to the 2022/2023 season, limiting the amount and 
                                            variety of testing data.
                                        </p>
                                        
                                        <p>
                                            <h3>Models</h3>
                                            Initially, I trained Scikit-learn MLPs and performed grid and Bayesian search hyperparameter optimization to tune these models. While I was able to get 
                                            decent accuracy metrics (as high as 100% on training and ~63% on test), these models were incredibly overconfident and overfitting. This overconfidence 
                                            resulted in poor betting performance, inspiring me to look for alternative solutions.
                                            <br /><br />
                                            In researching solutions to my overconfidence problem, I learned about structured probabilistic models and decided to explore the use of Bayesian neural
                                            networks. Continuing my research, I came across <a href="https://github.com/pyro-ppl/pyro" target="_blank" rel="noreffer noreferrer">Pyro</a>, an open-source
                                            probabilistic deep learning library. This library enabled me to implement and train BNNs using Monte Carlo and Stochastic Variational Inference methods,
                                            leading to significant improvements in addressing overfitting and overconfidence.
                                        </p>
                                        <p>
                                            <h3>Results and Future</h3>
                                            As of now, the best performance I have been able to yield from my models has been a 30% ROI when placing bets on 330 games out of ~1200 test games (figure 1).
                                            Although this return is high, increasing the number of bets while remaining profitable has been my focus. 
                                            <br /><br />
                                            Areas of further exploration include the development of recurrent neural networks to model streaky behavior in teams, increasing the size of test datasets,
                                            as well as modifying the betting algorithm.
                                            <br /><br />
                                            If any of this interests you, please check out my repository and feel free to reach out to me with any questions/concerns/comments about my models!
                                        </p>
                                        </div>
                                        <div className="project-images">
                                            <ul className="image-list">
                                                <li>
                                                    <figure>
                                                        <img src={bets_img} className="bet-image" alt="BNN Betting Performance" height={599} width={608} onClick={() => openModal(bets_img, "BNN Betting Performance")} />
                                                        <Modal
                                                            isOpen={modalOpen}
                                                            onClose={closeModal}
                                                            imageSrc={currentImage}
                                                            caption={caption}
                                                        />
                                                        <figcaption><i>Figure 1: Betting results on BNN </i></figcaption>
                                                    </figure>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </li>
                                
                                <li>
                                    <a href="https://github.com/Andrei-Treil/CS589-Final" target="_blank" rel="noreffer noreferrer" className="projLink">
                                    <h2>Machine Learning (CS 589) Final <FontAwesomeIcon icon={faGithub}/></h2></a>
                                    <div className="project-div">
                                        <div className="project-text">
                                            <p>
                                            <h3>Background</h3>
                                            As a part of the machine learning course at UMASS Amherst, students had to implement a variety of machine learning models from scratch. The final assessment for
                                            this course saw students using and adapting their models to learn a variety of new data sets. This repository highlights my strong foundations in machine learning,
                                            showing mastery over the core concepts of common machine learning models.
                                        </p>
                                        <p>
                                            <h3>Datasets and Models</h3>
                                            This project included 4 datasets:
                                            <ul>
                                                <li>MNIST Handrawn Numbers</li>
                                                <li>Titanic Survival Estimate</li>
                                                <li>Loan Eligibility Prediction</li>
                                                <li>Oxford Parkingson's Disease Detection</li>
                                            </ul>
                                            <br /><br />
                                            The models used were a K-Nearest-Neighbors model, a decision tree, a random forest, and a neural network. Accuracy and F1 score were obtained for each model using 
                                            on startified K-Fold cross validation for each dataset. The performance of the models on their respective datsets can be seen in figure 2.
                                            
                                        </p>
                                        </div>
                                        <div className="project-images">
                                            <ul className="image-list">
                                                <li>
                                                <figure>
                                                <img src={cs589_img} className="cs589-image" alt="Models and Dataset Performance" height={288} width={988} onClick={() => openModal(cs589_img, "BNN Betting Performance")}/>
                                                <Modal
                                                            isOpen={modalOpen}
                                                            onClose={closeModal}
                                                            imageSrc={currentImage}
                                                            caption={caption}
                                                        />
                                                <figcaption><i>Figure 2: Accuracy/F1 on Datasets</i></figcaption>
                                            </figure>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <a href="https://github.com/Andrei-Treil/EXAT-project" target="_blank" rel="noreffer noreferrer" className="projLink">
                                    <h2>EXAT Genomic Sequencing <FontAwesomeIcon icon={faGithub}/></h2></a>
                                    <div className="project-div">
                                        <div className="project-text">
                                            <p>
                                            <h3>Background</h3>
                                            During my final semester at UMASS Amherst, a friend of mine needed help adapting <a href="http://bibs.snu.ac.kr/software/EXAT/">genomic sequencing code</a>, written 
                                            by researchers from Seoul National University, for her biomedical engineering thesis. The project necessitated cleaning and piping genomic data into R in order to
                                            identify rare diseases using the code from SNU.                              
                                        </p>
                                        <p>
                                            <h3>Challenges and Results</h3>
                                            Due to the age of the provided code and lack of documentation, I had to perform significant debugging and fix any issues presented. Despite my lack of experience in R 
                                            and complexity of the subject matter, I was able to adapt the code to her specifications, resulting in the testing of nearly 6000 genomic sequences.
                                        </p>
                                        </div>
                                        <div className="project-images">
                                            <ul className="image-list">
                                                <li>
                                                    <figure>
                                                        <img src={exat_img} className="exat-image" alt="EXAT Code Snippet" height={488} width={608} onClick={() => openModal(exat_img, "BNN Betting Performance")}/>
                                                        <Modal
                                                            isOpen={modalOpen}
                                                            onClose={closeModal}
                                                            imageSrc={currentImage}
                                                            caption={caption}
                                                        />
                                                        <figcaption><i>Figure 3: EXAT.R Master Script </i></figcaption>
                                                    </figure>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </li> 
                        </ul>
                </div>
            </m.div>
        </>
    );
}