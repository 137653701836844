import { Route, Routes } from 'react-router-dom';
import About from './pages/About'
import Contact from './pages/Contact'
import Home from './pages/Home'
import Projects from './pages/Projects'
import Navbar from './Navbar'
import Resume from './pages/Resume.js';
import {motion as m} from 'framer-motion';
import { TypeAnimation } from 'react-type-animation';
import Caroline from './pages/Caroline.js';

function App() {
  return (
    <>
    <Navbar />
      <div className='container'>
        <Routes>
          <Route path='/' element={<Home />}></Route>
          <Route path='/home' element={<Home />}></Route>
          <Route path='/projects' element={<Projects/>}></Route>
          <Route path='/resume' element={<Resume/>}></Route>
          <Route path='/iloveyou' element={<Caroline/>}></Route>
        </Routes>
      </div>
      <div className='footer'>
        Andrei Treil <br />
        andrei.treil@gmail.com <br />
        Last Updated 8/23/2024
      </div>
      </>
  );
}

export default App;
