class GameState {
  constructor(grid, currentPlayer) {
    this.grid = grid;
    this.currentPlayer = currentPlayer;
  }

  isFull() {
    return this.grid.every(row => row.every(cell => cell !== null));
  }

  getWinner() {
    const directions = [
      { rowDir: 0, colDir: 1 },   // Horizontal
      { rowDir: 1, colDir: 0 },   // Vertical
      { rowDir: 1, colDir: 1 },   // Diagonal down-right
      { rowDir: 1, colDir: -1 }   // Diagonal down-left
    ];

    for (let row = 0; row < this.grid.length; row++) {
      for (let col = 0; col < this.grid[0].length; col++) {
        const player = this.grid[row][col];
        if (player === null) continue;

        for (const { rowDir, colDir } of directions) {
          let count = 1;

          for (let i = 1; i < 4; i++) {
            const newRow = row + i * rowDir;
            const newCol = col + i * colDir;

            if (newRow < 0 || newRow >= this.grid.length || newCol < 0 || newCol >= this.grid[0].length || this.grid[newRow][newCol] !== player) {
              break;
            }

            count++;
          }

          if (count >= 4) {
            return player;
          }
        }
      }
    }

    return null;
  }

  nextPlayer() {
    return this.currentPlayer;
  }

  successors() {
    const nextStates = [];
    for (let col = 0; col < this.grid[0].length; col++) {
      for (let row = this.grid.length - 1; row >= 0; row--) {
        if (this.grid[row][col] === null) {
          const newGrid = this.grid.map(row => [...row]);
          newGrid[row][col] = this.currentPlayer;
          nextStates.push([col, new GameState(newGrid, this.currentPlayer === 'Red' ? 'Yellow' : 'Red')]);
          break;
        }
      }
    }
    return nextStates;
  }

  utility() {
    const winner = this.getWinner();
    if (winner === 'Red') return 1;
    if (winner === 'Yellow') return -1;
    return 0;
  }
}

class RandomAgent {
  getMove(state) {
    const moves = state.successors();
    return Math.floor(Math.random() * moves.length);
  }
}

class MinimaxAgent {
  getMove(state) {
    const nextPlayer = state.nextPlayer();
    let bestMove = null;
    let bestValue = nextPlayer === 'Red' ? -Infinity : Infinity;

    for (const [move, newState] of state.successors()) {
      const value = this.minimax(newState);
      if ((nextPlayer === 'Red' && value > bestValue) || (nextPlayer === 'Yellow' && value < bestValue)) {
        bestMove = move;
        bestValue = value;
      }
    }

    return bestMove;
  }

  minimax(state) {
    const winner = state.getWinner();
    if (winner) return state.utility();

    let bestValue = state.nextPlayer() === 'Red' ? -Infinity : Infinity;

    for (const [, newState] of state.successors()) {
      const value = this.minimax(newState);
      if (state.nextPlayer() === 'Red') {
        bestValue = Math.max(bestValue, value);
      } else {
        bestValue = Math.min(bestValue, value);
      }
    }

    return bestValue;
  }
}

class MinimaxHeuristicAgent extends MinimaxAgent {
  constructor(depthLimit) {
    super();
    this.depthLimit = depthLimit;
  }

  minimax(state, depth = 0) {
    const winner = state.getWinner();
    if (winner || depth >= this.depthLimit) {
      return this.evaluation(state);
    }

    let bestValue = state.nextPlayer() === 'Red' ? -Infinity : Infinity;

    for (const [, newState] of state.successors()) {
      const value = this.minimax(newState, depth + 1);
      if (state.nextPlayer() === 'Red') {
        bestValue = Math.max(bestValue, value);
      } else {
        bestValue = Math.min(bestValue, value);
      }
    }

    return bestValue;
  }

  evaluation(state) {
    let score = 0;
    for (let row = 0; row < state.grid.length; row++) {
      for (let col = 0; col < state.grid[0].length; col++) {
        const player = state.grid[row][col];
        if (player === null) continue;

        score += this.evaluateDirection(state, row, col, 0, 1);   // Horizontal
        score += this.evaluateDirection(state, row, col, 1, 0);   // Vertical
        score += this.evaluateDirection(state, row, col, 1, 1);   // Diagonal down-right
        score += this.evaluateDirection(state, row, col, 1, -1);  // Diagonal down-left
      }
    }
    return score;
  }

  evaluateDirection(state, row, col, rowDir, colDir) {
    let streak = 0;
    let openEnds = 0;
    const player = state.grid[row][col];

    for (let i = 0; i < 4; i++) {
      const newRow = row + i * rowDir;
      const newCol = col + i * colDir;

      if (newRow < 0 || newRow >= state.grid.length || newCol < 0 || newCol >= state.grid[0].length) break;

      const currentCell = state.grid[newRow][newCol];
      if (currentCell === player) {
        streak++;
      } else if (currentCell === null) {
        openEnds++;
      } else {
        streak = 0
        break;
      }
    }

    let score = 0;

    if (streak === 4) {
      score = player === 'Yellow' ? -1000 : 1000;
    } else if (streak === 3) {
      if (openEnds > 1){
        score = player === 'Yellow' ? -1000 : 1000; // Prioritize almost-wins
      } else {
        score = player === 'Yellow' ? -100 : 100;
      }
    } else if (streak === 2 && openEnds > 0) {
      score = player === 'Yellow' ? -10 : 10;
    } else if (streak === 1 && openEnds > 0) {
      score = player === 'Yellow' ? -1 : 1;
    }

    return score;
  }
}


export { RandomAgent, MinimaxAgent, MinimaxHeuristicAgent, GameState };