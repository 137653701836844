import React, { useState, useEffect } from 'react';
import './Connect4.css';
import { MinimaxAgent, MinimaxHeuristicAgent, RandomAgent, GameState } from './Connect4Agent';

const ROWS = 6;
const COLUMNS = 7;

const Connect4 = () => {
  const [grid, setGrid] = useState(Array(ROWS).fill(null).map(() => Array(COLUMNS).fill(null)));
  const [currentPlayer, setCurrentPlayer] = useState('Red');
  const [winner, setWinner] = useState(null);
  const [movesLeft, setMovesLeft] = useState(ROWS * COLUMNS);
  const [selectedAgent, setSelectedAgent] = useState('Random'); 
  const [aiAgent, setAiAgent] = useState(new RandomAgent());
  const [lastMove, setLastMove] = useState(null); // Added state for last move
  const [selectedDepth, setSelectedDepth] = useState(3); 


  const checkWinner = (grid, row, col) => {
    const directions = [
      { rowDir: 0, colDir: 1 },   // Horizontal
      { rowDir: 1, colDir: 0 },   // Vertical
      { rowDir: 1, colDir: 1 },   // Diagonal down-right
      { rowDir: 1, colDir: -1 }   // Diagonal down-left
    ];

    for (const { rowDir, colDir } of directions) {
      let count = 1;

      count += countInDirection(grid, row, col, rowDir, colDir);
      count += countInDirection(grid, row, col, -rowDir, -colDir);

      if (count >= 4) return currentPlayer;
    }
    return null;
  };

  const countInDirection = (grid, row, col, rowDir, colDir) => {
    const player = grid[row][col];
    let count = 0;

    for (let i = 1; i < 4; i++) {
      const newRow = row + i * rowDir;
      const newCol = col + i * colDir;
      if (newRow < 0 || newRow >= ROWS || newCol < 0 || newCol >= COLUMNS || grid[newRow][newCol] !== player) {
        break;
      }
      count++;
    }
    return count;
  };


  const makeAIMove = (updatedGrid) => {
    if (winner) return;
    const newState = new GameState(updatedGrid, 'Yellow');
    const aiMove = aiAgent.getMove(newState);
    let newWinner = null;
    setCurrentPlayer('Yellow');
  
    for (let rowIndex = ROWS - 1; rowIndex >= 0; rowIndex--) {
      if (!updatedGrid[rowIndex][aiMove]) {
        updatedGrid[rowIndex][aiMove] = 'Yellow';
        newWinner = checkWinner(updatedGrid, rowIndex, aiMove);
        setMovesLeft(prev => prev - 1);
  
        if (newWinner) {
          setWinner('Yellow');
        } else if (movesLeft - 1 === 0) {
          setWinner('Tie');
        } else {
          setCurrentPlayer('Red');
        }
  
        setGrid(updatedGrid);
        setLastMove({ row: rowIndex, col: aiMove });
        break;
      }
    }
  }

  const handleClick = (columnIndex) => {
    if (winner) return; // Do nothing if there's already a winner
  
    let moveMade = false;
    const newGrid = grid.map(row => [...row]);
    let newWinner = null;
    for (let rowIndex = ROWS - 1; rowIndex >= 0; rowIndex--) {
      if (!newGrid[rowIndex][columnIndex]) {
        newGrid[rowIndex][columnIndex] = currentPlayer;
  
        newWinner = checkWinner(newGrid, rowIndex, columnIndex);
        setMovesLeft(prev => prev - 1);
  
        if (newWinner) {
          setWinner('Red');
        } else if (movesLeft - 1 === 0) {
          setWinner('Tie');
        } else {
          setCurrentPlayer('Yellow');
        }
  
        setGrid(newGrid);
        setLastMove({ row: rowIndex, col: columnIndex });
        moveMade = true;
        break;
      }
    }

    // After the player has made their move, let the AI make its move
    if (moveMade && !newWinner) {
      setTimeout(makeAIMove,500,newGrid);
    }
  };
  


  const startNewGame = () => {
    setGrid(Array(ROWS).fill(null).map(() => Array(COLUMNS).fill(null)));
    setCurrentPlayer('Red');
    setWinner(null);
    setMovesLeft(ROWS * COLUMNS);
  };

  const handleAgentChange = (agentType) => {
    let agent;
    switch (agentType) {
      case 'Random':
        agent = new RandomAgent();
        break;
      case 'Minimax':
        agent = new MinimaxAgent();
        break;
      case 'MinimaxHeuristic':
        agent = new MinimaxHeuristicAgent(selectedDepth); // Depth limit of 3
        break;
      default:
        agent = new RandomAgent();
    }
    setAiAgent(agent);
    setSelectedAgent(agentType);
  };

  const updateAgentDepth = (depth) => {
    setSelectedDepth(depth);
    let agent = new MinimaxHeuristicAgent(depth);
    setAiAgent(agent);
    setSelectedAgent('MinimaxHeuristic');
  };

  return (
    <div className="connect4">
      <div className='board'>
        <h2>{winner ? (winner === 'Tie' ? 'It\'s a Tie!' : `${winner} Wins!`) : `${currentPlayer}'s turn`}</h2>
        <div className="connect4-grid">
          {grid.map((row, rowIndex) =>
            row.map((cell, columnIndex) => (
              <div
                key={`${rowIndex}-${columnIndex}`}
                className="connect4-cell"
                onClick={() => handleClick(columnIndex)}
                style={{ backgroundColor: cell || 'white',
                  border: lastMove && lastMove.row === rowIndex && lastMove.col === columnIndex ? '3px solid black' : '1px solid grey'
                }}
              ></div>
            ))
          )}
        </div>
        {(
          <div>
            <button onClick={startNewGame}>Start New Game</button>
          </div>
        )}
      </div>
      <div className="agent-selection">
        <h2>Select an Agent:</h2>
        <div className='agents'>
          <button
              className={selectedAgent === 'Random' ? 'selected' : ''}
              onClick={() => handleAgentChange('Random')}
            >
              Random Agent
            </button>
            <button
              className={selectedAgent === 'MinimaxHeuristic' ? 'selected' : ''}
              onClick={() => handleAgentChange('MinimaxHeuristic')}
            >
              Minimax Heuristic Agent
            </button>
        </div>
      </div>
      <div className='depth-select'>
      <h3>Depth:</h3>
        <button onClick={() => updateAgentDepth(3)} className={selectedDepth === 3 ? 'selected' : ''}>
          3
        </button>
        <button onClick={() => updateAgentDepth(4)} className={selectedDepth === 4 ? 'selected' : ''}>
          4
        </button>
        <button onClick={() => updateAgentDepth(5)} className={selectedDepth === 5 ? 'selected' : ''}>
          5
        </button>
        <button onClick={() => updateAgentDepth(6)} className={selectedDepth === 6 ? 'selected' : ''}>
          6
        </button>
        <button onClick={() => updateAgentDepth(7)} className={selectedDepth === 7 ? 'selected' : ''}>
          7
        </button>
      </div>
      <style jsx>{`
                    .depth-select{
                        display: ${selectedAgent === 'MinimaxHeuristic' ? 'flex' : 'none'};
                    }
                `}
        </style>
    </div>
  );
};


export default Connect4;