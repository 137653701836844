import './Caroline.css';
import Timer from './Timer';
import caro_swim_gogs from '../secret_images/caro_swim_goggles.jpg';
import caro_ppe from '../secret_images/caro_ppe.jpeg';
import caro_double_glasses from '../secret_images/caro_double_glasses.jpeg';
import caro_grad from '../secret_images/caro_grad.jpg';
import tyler_mead from '../secret_images/tyler_mead.jpg';
import mead_bot from '../secret_images/mead_bot.jpg';
import caro_leaf from '../secret_images/caro_leaf.jpg';
import caro_rip from '../secret_images/caro_box_rip.jpg';
import caro_paint from '../secret_images/caro_painting.jpg';
import caro_ushank from '../secret_images/caro_ushank.jpg';
import dub_drink from '../secret_images/dub_drink.jpg';
import caro_horns from '../secret_images/caro_horns.jpg';
import caro_sus from '../secret_images/caro_sus.jpg';
import park_self from '../secret_images/park_selfie.jpg';
import caro_nood_smile from '../secret_images/caro_nood_smile.jpg';
import caro_4th from '../secret_images/caro_4th.jpg';
import caro_selfie from '../secret_images/caro_selfie.jpg';
import bread from '../secret_images/bread.jpg';
import bite from '../secret_images/bite.jpg';
import white_trash from '../secret_images/white_trash.jpg';
import cute_fit from '../secret_images/cute_fit.jpg';
import legs_open from '../secret_images/legs_open.jpg';
import new_years from '../secret_images/new_years.jpg';
import maine from '../secret_images/maine.jpg';
import elise_bday from '../secret_images/elise_bday.jpg';
import we_kiss from '../secret_images/we_kiss.jpg';
import we_kiss2 from '../secret_images/we_kiss2.jpg';
import with_dog from '../secret_images/with_dog.jpg';
import leafs from '../secret_images/leafs.jpg';
import at_lake from '../secret_images/at_lake.jpg';
import ani1 from '../secret_images/ani1.jpg';
import ani2 from '../secret_images/ani2.jpg';
import ani3 from '../secret_images/ani3.jpg';
import ani4 from '../secret_images/ani4.jpg';
import ani5 from '../secret_images/ani5.jpg';
import ani6 from '../secret_images/ani6.jpg';
import { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import Confetti from "react-confetti";


export default function Caroline(){
    useEffect(() => {
        const origBackColor = document.body.style.backgroundColor;
        document.body.style.backgroundColor = '#086728';
        return () => document.body.style.fontSize = origBackColor;
    }, []);

    const [showConfetti,setConfetti] = useState(false);
    const [isAniversary, setAniversary] = useState(false);
    
    useEffect(() => {

            const now = new Date();
            const startDate = new Date("November, 13, 2023"); // Replace with the actual start date
            const diffTime = Math.abs(now - startDate);
            const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

            if (diffDays === 366) {
                //setConfetti(true);
                Swal.fire({
                    title: 'Happy Anniversary!',
                    text: 'I love you Caroline!',
                    //icon: 'success',
                    confirmButtonText: 'I love you too :)',
                    target: document.querySelector('.caro-div-intro'),
                    padding: '0 0 1rem',
                    width: '20rem',
                    heightAuto: false,
                }).then((result) => {
                    if (result.isConfirmed) {
                        setConfetti(false);
                    }
                });     
            }
    }, []);

    useEffect(() => {

        const now = new Date();
        const startDate = new Date("November, 13, 2023"); // Replace with the actual start date
        const diffTime = Math.abs(now - startDate);
        const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

        if (diffDays === 366) {
            setConfetti(true);
            setAniversary(true);
        }
    }, []);

    return (<>
            {showConfetti && <Confetti/>}
                
            <div className='caro-div-intro'>
            <div className='ani-div'>
                    <h1>Happy Aniversary!</h1>
                    <div className='timer-div-ani'>
                        <h2>We Have Been Dating for: </h2>
                        <Timer></Timer>
                        
                    </div>
                    <p>Time flies when you spend it with someone you love :)</p>
                    <br />
                    
                    
                    <h3>Here are some of my favorite pictures of us, I can't wait to take so many more!</h3>
                    <img src={at_lake} className="caro-swim-gogs" alt="My Beautiful GF at The Pond" height={800} width={1200}/>
                    <div className='caro-imgs'>
                        <ul>
                            <li>
                                <img src={we_kiss} className="caro-swim-gogs" alt="My Beautiful GF at The Pond" height={460} width={345}/>
                            </li>
                            <li>
                                <img src={we_kiss2} className="caro-swim-gogs" alt="My Beautiful GF at The Pond" height={460} width={345}/>
                            </li>
                            <li>
                                <img src={with_dog} className="caro-swim-gogs" alt="My Beautiful GF at The Pond" height={460} width={345}/>
                            </li>
                            <li>
                                <img src={leafs} className="caro-swim-gogs" alt="My Beautiful GF at The Pond" height={460} width={345}/>
                            </li>
                            <li>
                                <img src={ani1} className="caro-swim-gogs" alt="My Beautiful GF at The Pond" height={460} width={345}/>
                            </li>
                            <li>
                                <img src={ani2} className="caro-swim-gogs" alt="My Beautiful GF at The Pond" height={460} width={345}/>
                            </li>
                            <li>
                                <img src={ani3} className="caro-swim-gogs" alt="My Beautiful GF at The Pond" height={460} width={345}/>
                            </li>
                            <li>
                                <img src={ani4} className="caro-swim-gogs" alt="My Beautiful GF at The Pond" height={460} width={345}/>
                            </li>
                            <li>
                                <img src={ani5} className="caro-swim-gogs" alt="My Beautiful GF at The Pond" height={460} width={345}/>
                            </li>
                            <li>
                                <img src={ani6} className="caro-swim-gogs" alt="My Beautiful GF at The Pond" height={460} width={345}/>
                            </li>
                        </ul>
                        <p>I am incredibly grateful for you, I can't wait to go to Acadia!</p>
                    <br />
                    </div>
                    <div className='love-note-ani'>
                        <h1>I love you so so much</h1>
                        <p>Thank you for being such an amazing, wonderful person. You bring me so much happiness and I'm incredibly grateful.</p>
                        <p>I am so proud of you for all that you accomplish, and I am so proud of the person you have helped me become.</p>
                        <p>I feel so lucky to have met you, and even luckier to get call you mine.</p>
                        <p>You are dope and swag and epic</p>
                        <h2>I love you, Caroline</h2>
                        <br />
                        <p>(ignore the footer I can't get rid of it sorry)</p>
                        
                    </div>
                </div>
                <div className='intro-text'>
                    <h1>This is Caroline! (My Girlfriend)</h1>
                    <img src={caro_swim_gogs} className="caro-swim-gogs" alt="My Beautiful GF at The Pond" height={460} width={345}/>
                    <p>This was taken at Walden pond, when we were celebrating 24 years of my favorite person being on Earth :3</p>
                    <br />
                </div>
                <div className='timer-div'>
                    <h2>We Have Been Dating for: </h2>
                    <Timer></Timer>    
                    <p>Time flies when you spend it with someone you love :)</p>
                    <br />
                </div>
            </div>
            <div className='caro-div'>
                <h2>On top of being my beautiful girlfriend, Caroline is also a scientist!</h2>
                <div className='caro-imgs'>
                    <ul>
                        <li>
                            <img src={caro_ppe} className="caro-swim-gogs" alt="My Beautiful GF at The Pond" height={460} width={345}/>
                        </li>
                        <li>
                            <img src={caro_double_glasses} className="caro-swim-gogs" alt="My Beautiful GF at The Pond" height={460} width={345}/>
                        </li>
                        <li>
                            <img src={caro_grad} className="caro-swim-gogs" alt="My Beautiful GF at The Pond" height={460} width={345}/>
                        </li>
                    </ul>
                </div>
                <p>As a food scientist, Caroline gets to show off her smarts all the time</p>
                <br />
            </div>
            <div className='caro-div'>
                <h2>Like when we made mead together!</h2>
                <h3>(Even if she did need some supervision from Tyler)</h3>
                <div className='caro-imgs'>
                    <ul>
                        <li>
                            <img src={tyler_mead} className="caro-swim-gogs" alt="My Beautiful GF at The Pond" height={576} width={324}/>
                        </li>
                        <li>
                            <img src={mead_bot} className="caro-swim-gogs" alt="My Beautiful GF at The Pond" height={460} width={345}/>
                        </li>
                    </ul>
                </div>
                <br />
            </div>
            <div className='caro-div'>
                <h2>Despite being a super smart scientist, she is known to get silly</h2>
                <div className='caro-imgs'>
                    <ul>
                        <li>
                            <img src={caro_rip} className="vert_img" alt="My Beautiful GF at The Pond" height={576} width={324}/>
                            like when she opened a pack of high noons like this...
                        </li>
                        <li>
                            <img src={dub_drink} className="vert_img" alt="My Beautiful GF at The Pond" height={460} width={345}/>
                            or when she was double fisting cocktails at NYAJ...
                        </li>
                        <li>
                            <img src={caro_ushank} className="vert_img" alt="My Beautiful GF at The Pond" height={460} width={345}/>
                            or when she dressed up to smoke outside...
                        </li>
                        <li>
                            <img src={bread} className="caro-swim-gogs" alt="My Beautiful GF at The Pond" height={460} width={345}/>
                            or when she got so much bread...
                        </li>
                        <li>
                            <img src={caro_sus} className="caro-swim-gogs" alt="My Beautiful GF at The Pond" height={460} width={345}/>
                            or when we went out to get ramen!
                        </li>
                    </ul>
                </div>
                <p>I would show more but I know Caroline doesn't like silly pictures of her (even though I love them)</p>
                <br />
            </div>
            <div className='caro-div'>
                <h2>So instead, here are some of my favorite non-silly pictures :)</h2>
                <div className='caro-imgs'>
                    <ul>
                        <li>
                            <img src={caro_4th} className="caro-swim-gogs" alt="My Beautiful GF at The Pond" height={460} width={345}/>
                        </li>
                        <li>
                            <img src={caro_selfie} className="caro-swim-gogs" alt="My Beautiful GF at The Pond" height={460} width={345}/>
                        </li>
                        <li>
                            <img src={caro_nood_smile} className="caro-swim-gogs" alt="My Beautiful GF at The Pond" height={576} width={324}/>
                        </li>
                        <li>
                            <img src={caro_paint} className="caro-swim-gogs" alt="My Beautiful GF at The Pond" height={460} width={345}/>
                        </li>
                        <li>
                            <img src={cute_fit} className="caro-swim-gogs" alt="My Beautiful GF at The Pond" height={460} width={345}/>
                        </li>
                    </ul>
                </div>
                <p>There are so many more I can't decide which ones to put!</p>
                <br />
            </div>
            <div className='caro-div'>
                <h2>And I wanted to include my favorite pics of us together :3</h2>
                <div className='caro-imgs'>
                    <ul>
                        <li>
                            <img src={new_years} className="caro-swim-gogs" alt="My Beautiful GF at The Pond" height={460} width={345}/>
                        </li>
                        <li>
                            <img src={maine} className="caro-swim-gogs" alt="My Beautiful GF at The Pond" height={460} width={345}/>
                        </li>
                        <li>
                            <img src={white_trash} className="caro-swim-gogs" alt="My Beautiful GF at The Pond" height={460} width={345}/>
                        </li>
                        <li>
                            <img src={bite} className="caro-swim-gogs" alt="My Beautiful GF at The Pond" height={576} width={324}/>
                        </li>
                        <li>
                            <img src={elise_bday} className="caro-swim-gogs" alt="My Beautiful GF at The Pond" height={460} width={345}/>
                        </li>
                        <li>
                            <img src={legs_open} className="caro-swim-gogs" alt="My Beautiful GF at The Pond" height={460} width={345}/>
                        </li>
                    </ul>
                </div>
                <br />
            </div>
            <div className='love-note'>
                <h1>I hope you like this page I made for you (dont worry I'm still updating it!)</h1>
                <h2>
                    Here is the poem I wrote you in case you ever want to read it: <br />
                </h2>
                <div className='poem'>
                    <p>
                        I did not know love until you showed me <br />
                        your gentle touch and sweet words <br />
                        before we met I felt so lonely <br />
                        I am grateful that we share this world. <br />
                    </p>
                    <p>
                        Your perfect eyes, a forest river <br />
                        glowing in the summer sun. <br />
                        A blooming flower that will not wither, <br />
                        our loving journey has just begun. <br />
                    </p>
                    <p>
                        And even though i feel unworthy, <br />
                        you convince me I'm enough. <br />
                        And even though I'm still learning, <br />
                        I will show you all my love. <br />
                    </p>
                    <p>
                        And with each day, <br />
                        that I get to call you mine. <br />
                        The more I need to say... <br />
                    </p>
                    <h1>I love you Caroline!</h1>
                </div>
            </div>
            <style jsx>{`
                    .intro-text{ 
                        display: ${isAniversary ? 'none' : 'grid'};
                    }
                    .caro-div{
                        display: ${isAniversary ? 'none' : 'inherit'};
                    }
                    .timer-div{
                        display: ${isAniversary ? 'none' : 'inherit'};
                    }
                    .ani-div{
                        display: ${isAniversary ? 'flexbox' : 'none'};
                    }
                    .love-note{
                        display: ${isAniversary ? 'none' : 'flex'};
                    }
                    .love-note-ani{
                        display: ${isAniversary ? 'flex' : 'none'};
                    }

                `}
        </style>
    </>)
}