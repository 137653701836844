import { Link, useMatch, useResolvedPath } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub, faLinkedinIn } from '@fortawesome/free-brands-svg-icons'
import { useLocation } from "react-router-dom";
import React, { useState, useEffect } from 'react';
//import { byPrefixAndName } from '@awesome.me/kit-KIT_CODE/icons'

export default function Navbar() {
    const withouSidebarRoutes = ["/iloveyou"];
    const {pathname} = useLocation();
    const [hamburgerOpen, setHamburgerOpen] = useState(false);

    const toggleHamburger = () => {
        setHamburgerOpen(!hamburgerOpen);
    }

    // Validates if the current pathname includes one the routes you want to hide the sidebar is present on the current url
    // If that's true render null instead of the sidebar
    if (withouSidebarRoutes.some((item) => pathname.includes(item))) return null;

    return <nav className="navbar">
            <div className="navbar-div">
                    <div class='name-list'>
                        <Link to="/" className="site-title">
                            Andrei Treil
                        </Link>
                        <div class='contact-info'>
                            <a href="https://github.com/Andrei-Treil" target="_blank" rel="noreffer noreferrer" className="ghIcon">GitHub <FontAwesomeIcon icon={faGithub}/></a>
                            <a href="https://www.linkedin.com/in/andreitreil/" target="_blank" rel="noreffer noreferrer"  className="liIcon">LinkedIn <FontAwesomeIcon icon={faLinkedinIn}/></a>
                        </div>
                    </div>
                    
                    <div class='burger'>
                        <ul className="page-list">
                            <CustomLink to="/">Home</CustomLink>
                            <CustomLink to="/projects">Projects</CustomLink>
                            <CustomLink to="/resume">Resume</CustomLink>
                        </ul>
                        <div class="hamburger-menu" onClick={toggleHamburger}>
                            &#9776;
                        </div>
                    </div>
            </div>
            <style jsx>{`
                    @media (max-width: 768px) {
                        .burger{
                            align-self: flex-start;
                        }
                        .hamburger-menu {
                            display: fixed;
                        }
                        
                        .page-list {
                            display: ${hamburgerOpen ? 'grid' : 'none'};
                            flex-direction: ${hamburgerOpen ? 'column' : 'none'};
                        }
                    }
                `}
        </style>
    </nav>
}

function CustomLink( {to, children, ...props}){
    const resolvedPath = useResolvedPath(to)
    const isActive = useMatch({ path: resolvedPath.pathname, end: true})
    return (
        <li className={isActive ? 'active' : ''}>
            <Link to={to}{...props}>{children}</Link>
        </li>
    )
}
